import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getFormattedDate, getNowHour, getNowMinutes, isBefore, isSame } from "./Formatters";
import { IPedido } from "../interfaces/Pedidos";

dayjs.extend(customParseFormat);

const ruta1mH1 = dayjs('12:25:01', 'HH:mm:ss');;
const ruta1mH2 = dayjs('16:55:00', 'HH:mm:ss');

const ruta2mH1 = dayjs('16:55:01', 'HH:mm:ss');
const ruta2mH2 = dayjs('23:54:59', 'HH:mm:ss');

const ruta2hH1 = dayjs('23:55:00', 'HH:mm:ss').subtract(1, 'day');
const ruta2hH2 = dayjs('12:25:00', 'HH:mm:ss');

/**
 *  Obtiene la ruta de entrega
 * @param formaEntrega Forma de entrega del pedido
 * @returns La forma de entraga basada en la hora, si se suministra una forma de entrega que pasa por 
 * alto la hora (CLIENTE RECOLECTA o ALGARIN RECOLECTA) se regresa 'SIN RUTA'
 */
export const getRutaEntrega = (formaEntrega: string): string => {
    if (formaEntrega === "CLIENTE RECOLECTA" || formaEntrega === "ALGARIN RECOLECTA") {
        return 'SIN RUTA';
    }
    return '';
}

/**
 * Obtiene la fecha de entrega sugeridad basado en la fecha actual y la configuracion de las rutas turno
 * @returns  Fecha de entrega sugerida
 */
export const getFechaEntrega = (): Date => {
    const now = new Date();
    const futureDate = new Date();
    const hora = dayjs(now);//now.toLocaleTimeString('es-MX');
    if (((hora.isAfter(ruta1mH1) || hora.isSame(ruta1mH2)) && (hora.isBefore(ruta1mH2) || hora.isSame(ruta1mH2))) || //(hora >= ruta1mH1 && hora <= ruta1mH2) ||
        ((hora.isAfter(ruta2mH1) || hora.isSame(ruta2mH1)) && (hora.isBefore(ruta2mH2) || hora.isSame(ruta2mH2)))) { //(hora >= ruta2mH1 && hora <= ruta2mH2)) {
        if (now.getDay() === 5) {
            futureDate.setDate(now.getDate() + 3);
        } else if (now.getDay() === 6) {
            futureDate.setDate(now.getDate() + 2);
        } else {
            futureDate.setDate(now.getDate() + 1);
        }
    } else if (hora.isAfter(ruta2hH1) && hora.isBefore(ruta2hH2)) {
        if (now.getDay() === 6) {
            futureDate.setDate(now.getDate() + 2);
        } else if (now.getDay() === 0) {
            futureDate.setDate(now.getDate() + 1);
        }
    }
    return futureDate;
}

/**
 * Este metodo es utilizado para determinar si es necesario desabilitar la seleccion de ruta.
 * (La logica aqui presente viene del sistema legacy sin mayores analisis por temas del tiempo y la carga cognitiva)
 * @param selectedDate  Fecha seleccionada
 * @returns 
 */
export const disableRoute = (selectedDate: Date): boolean => {
    const now = dayjs(new Date());
    const hora = dayjs(now);
    const nowPlusTwoDays = dayjs(new Date()).add(2, 'day');
    const selectedDateParsed = dayjs(selectedDate);
    if (now.isSame(selectedDateParsed) || selectedDateParsed.isBefore(now)) {
        return true;
    } else if (nowPlusTwoDays.isSame(selectedDateParsed) || nowPlusTwoDays.isBefore(selectedDateParsed)) {
        return false;
    } else {
        if (now.isBefore(selectedDateParsed)) {
            if (hora.isBefore(ruta1mH2)) {
                return false;
            } else {
                return true;
            }
        }
    }
    return false;
}

/**
 *  Valida la fecha de entrega seleccionada para luego retornar un mensaje de error de ser necesario
 * @param selectedDate 
 * @param pedido 
 * @returns 
 */
export const validateFechaEntrega = (selectedDate: Date, pedido: IPedido): string | null => {
    var fechaPedidoParsed = getFormattedDate(pedido.fecha);
    const now = new Date();
    if (isBefore(selectedDate, now)) {
        return "La fecha de entrega no puede ser menor a la fecha actual.";
    } else if (isBefore(selectedDate, fechaPedidoParsed)) {
        return "La fecha de entrega no puede ser menor a la fecha del pedido.";
    } else if (isSame(selectedDate, now)) {
        const hour = getNowHour(); // Solo la hora
        const minute = getNowMinutes(); // Solo los minutos
        if ((pedido.idFentrega !== "CLIENTE RECOLECTA" && pedido.idFentrega !== "ALGARIN RECOLECTA")) {
            if (hour > 13) {
                return "La ruta ya cerró selecciona otra fecha";
            } else if (hour === 13) {
                if (minute >= 30) {
                    return "La ruta ya cerró selecciona otra fecha";
                }
            }
        } else {
            return null;
        }
    } else if (selectedDate.getDay() === 0 || selectedDate.getDay() === 6) {
        return "Estimado usuario la fecha de entrega no es un día hábil";
    } else if (selectedDate === null || selectedDate === undefined) {
        return 'Ingresa una fecha valida.';
    } else {
        return null;
    }
    return null;
}