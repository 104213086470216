import { Alert, Button, Divider, Flex, Grid, Modal, Select, rem, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useForm, UseFormInput } from "@mantine/form";
import { IPedido } from "../../commons/interfaces/Pedidos";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import { disableRoute, getFechaEntrega, getRutaEntrega, validateFechaEntrega } from "../../commons/utils/RouteUtils";
import { DatePickerInput } from "@mantine/dates";
import { useEffect, useMemo, useState } from "react";
import { getFormattedDateString } from "../../commons/utils/Formatters";
import React from "react";
import useRequest from "../../hooks/useRequest";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);

type Closure = {
    id: number,
    label: string,
    position: number,
    tolerance: number,
    hour: string,
    weekdays: number[]
}

interface ModalSelectRutaProps {
    openModal: boolean;
    continueCallback: (selectedDate: string, selectedRoute: string) => void;
    onCloseCallback: () => void;
    pedido: IPedido;
}

export const ModalSelectRuta: React.FC<ModalSelectRutaProps> = ({
    openModal,
    continueCallback,
    onCloseCallback,
    pedido
}) => {
    const tz = "America/Mexico_City";


    const [sinRuta, setSinRuta] = useState<boolean>(false);
    const [emptyClosures, setEmptyClosures] = useState<boolean>(false);
    const [disableSelectRuta, setDisableSelectRuta] = useState<boolean>(true);
    const [closures, setClosures] = useState<Closure[]>();
    const [opened, { open, close }] = useDisclosure(openModal);
    const { executeRequest, executeRequestAndCallbackData } = useRequest();

    const CalendarIcon = <FontAwesomeIcon icon={faCalendar}  style={{ width: rem(16), height: rem(16) }} />;
    const ClockIcon = <FontAwesomeIcon icon={faClock}  style={{ width: rem(16), height: rem(16) }} />;

    const fechaEntrega = getFechaEntrega();

    const form = useForm({
        initialValues: {
            rutaEntrega: sinRuta ? 'Sin Ruta' : getRutaEntrega(pedido.idFentrega),
            fechaEntrega: fechaEntrega
        },
        validate: {
            rutaEntrega: (value) => {
                return (value === '' || value === undefined ? 'Ingresa un cierre valida.' : null);
            },
            fechaEntrega: (value) => {
                return validateFechaEntrega(value, pedido);
            }
        },
        onValuesChange(values, previous) {
            if (values.fechaEntrega !== null && values.fechaEntrega !== undefined) {
                setDisableSelectRuta(disableRoute(values.fechaEntrega));
            }
        },

    });

    useEffect(() => {
        if (pedido.idFentrega === "CLIENTE RECOLECTA" || pedido.idFentrega === "ALGARIN RECOLECTA") {
            setSinRuta(true);
            form.setFieldValue('fechaEntrega', new Date());
        }
    }, [pedido]);


    const onSubmit = (values: any) => {
        const selectedDate = getFormattedDateString(values.fechaEntrega);
        const selectedRoute = values.rutaEntrega;
        continueCallback(selectedDate, selectedRoute);
        close();
    }

    const onClose = () => {
        executeRequest({
            url: `/sales/anticipos?action=select-anticipo`,
            method: 'POST',
            body: {
                pedido: pedido.idPedido,
                tkmov: pedido.tkmov,
                empresa: pedido.idEmpresa,
                idCliente: pedido.idCliente,
                montoAnticipo: 0
            },
            silentFailure: true
        });
        onCloseCallback();
        close();
    }

    /**
     * Evaluate date of calendar to valid follow rules
     *  - Not show past dates
     *  - Only show availables weekdays from closures
     * @param date
     * @returns boolean
     */
    const excludeDates = (date: Date) => {
        let isExclude = false;
        // Exclude past days
        if (date.getTime() < new Date().setUTCHours(0,0,0,0)) {
            isExclude = true;
        }

        // Exclude weekdays not included
        try {
            closures?.forEach(c => {
                if (!c.weekdays.includes(date.getDay())) {
                    throw new Error("Invalid date");
                }
            })
        } catch (e) {
            isExclude = true;
        }

        return isExclude;
    }

    /**
     * Validate hour comparing with current now date
     * @param hour
     * @returns boolean
     */
    const hourMustBeDisabled = (closure: Closure, selectDate: Date) => {
        // Check if select date weekday is not allowed
        const weekday = selectDate.getDay();
        if (!closure.weekdays.includes(weekday)) {
            return true;
        }

        // Check if is today restrictions
        const _date = dayjs(selectDate);
        if (!_date.isToday()) return false;
        const [h, m] = closure.hour.split(':');
        const date = dayjs().tz(tz)
            .set('hour', Number.parseInt(h))
            .set('minute', Number.parseInt(m));

        return date.isBefore(dayjs().tz(tz));
    }

    const loadDefaultClosures = () => {
        executeRequestAndCallbackData({
            showLoadingOverlay: false,
            url: '/logistics/closures?cedis=DEFAULT',
            callback: (data) => {
                setClosures(data);
            }
        });
    }

    useEffect(() => {
        executeRequestAndCallbackData({
            showLoadingOverlay: false,
            url: `/logistics/closures?cedis=${pedido.idFentrega}`,
            callback: (data) => {
                setClosures(data);
            }
        })
    }, []);

    useEffect(() => {
        setEmptyClosures(closures?.length == 0);
    }, [closures]);

    const closuresSelect = useMemo(() => {
        return closures?.map(c => {
            return {
                value: `${c.id}-${c.hour}`,
                label: `${c.label} (hora cierre ${c.hour})`,
                disabled: hourMustBeDisabled(c, form.getInputProps('fechaEntrega').value)
            }
        });
    }, [closures, form.getInputProps('fechaEntrega')]);

    return (
        <>
            <Modal opened={opened} onClose={onClose} title="Fecha de entrega">
                <Divider my="sm" />
                <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                    <Grid gutter="lg" justify="center">

                        <Grid.Col span={12} >
                            <DatePickerInput
                                valueFormat="DD-MM-YYYY"
                                leftSection={CalendarIcon}
                                leftSectionPointerEvents="none"
                                placeholder="Fecha de entrega"
                                excludeDate={excludeDates}
                                {...form.getInputProps('fechaEntrega')}
                            />
                        </Grid.Col>
                        
                        { emptyClosures && !sinRuta &&
                            <Grid.Col span={12}>
                                <Alert variant="light" color="orange" radius="sm" title="Atención" icon={CalendarIcon}>
                                    No hay rutas (horarios de cierres) configurados para el centro de distribución asociado <b>({pedido.idFentrega})</b>.
                                    ¿quieres utilizar los horarios por defecto?
                                    <Divider my="xs" variant="dashed" color="transparent"/>
                                    <Button variant="light" color="orange" size="xs" radius="xl" onClick={loadDefaultClosures}>Cargar horarios por defecto</Button>
                                    <Divider my="xs" variant="dashed" color="transparent"/>
                                    <Text size="xs" c="dimmed">Estos valores pueden ser configurados en forma permanente en el servicio de logística.</Text>
                                </Alert>
                            </Grid.Col>
                        }
                        { !sinRuta &&
                            <Grid.Col span={12}>
                                <Select
                                    allowDeselect={false}
                                    checkIconPosition="right"
                                    leftSection={ClockIcon}
                                    label={<Text>Elige una ruta <Text span c="dimmed">(horario de cierre)</Text></Text>}
                                    description="Ruta (horario de cierre para el inicio del despacho)"
                                    comboboxProps={{ shadow: 'md' }}
                                    data={closuresSelect}
                                    {...form.getInputProps('rutaEntrega')}
                                />
                            </Grid.Col>
                        }
                        { sinRuta &&
                            <Grid.Col span={12}>
                                <Text size="sm" c="dimmed">Este pedido será retirado por el cliente</Text>
                            </Grid.Col>
                        }
                    </Grid>
                    <Divider my="sm" />
                    <Flex
                        mih={50}
                        gap="md"
                        justify="space-between"
                        align="flex-end"
                        direction="row"
                        wrap="wrap"
                    >
                        <Button variant="outline" onClick={() => onClose()}>Cerrar</Button>
                        <Button variant="primary" type="submit">Aplicar</Button>
                    </Flex>
                </form>
            </Modal>
        </>
    );
}